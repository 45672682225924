'use strict'

var base = module.superModule;

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

$('body').off('click.producttile').on('click.producttile', '.tile-add-to-cart', function (e) {
    e.preventDefault();
    var productTile = $(this).parents('.product-tile');
    if (!getCookie('dw_shippostalcode')) {
        $('#popUpCep').slideDown();

        $('#popUpCep').modal('show'); //MOSTRA O MODAL DE CEP
    } else {
        // var suppressMinicart = $(this).parents('.promo-modal').length > 0 && !$('.pt_beforeyougo').length;
        addToCart(productTile, !$('.mini-cart-empty').length);
    }
});

function addToCart(productTile, suppressMinicart) {
    if (productTile){
        var pid = productTile.find('.product-options').attr('data-pid');
        var jqXHR = $.ajax({
            method: 'post',
            url: window.Urls.tileAddToCart,
            data: {
                pid: pid,
                Quantity: 1
            }
        });

        // Show the progress indicator over the product tile
        // progress.show(productTile);

        jqXHR.done(function (html) {
            $('.product-tile[data-itemid="' + pid + '"]').replaceWith(html);

            $(document).trigger('toastNotify', 'added');

            // TODO: Re-initialize the product ratings when implemented

            $(document).trigger({
                type: 'basketupdate',
                pid: pid,
                quantity: 1,
                source: 'tile',
                suppress: suppressMinicart
            });
        });

        // Make sure to remove the progress indicator after the Ajax call
        jqXHR.always(function () {
            // progress.hide();
        });

        return jqXHR;
    }
}

module.exports = base;
